/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
  ==========================================================================================*/

  import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
  import themeConfig from "@/../themeConfig.js"
  import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////
let userData = {};
if(localStorage.getItem("user")) {
  try{    
    userData.image = localStorage.getItem("user-image");
    userData.fullName = `${JSON.parse(localStorage.getItem("user")).first_name} ${JSON.parse(localStorage.getItem("user")).last_name}`
  } catch (ex)
  {
    console.error("Unable to parse user data");
  }
}


const userDefaults = {
  uid         : 0, // From Auth
  first_name  : userData.fullName, // From Auth 
  about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL    : require("@/assets/images/logo/profile.png"), // From Auth
  // photoURL    : userData.image, // From Auth
  status      : "online",
  userRole    : "admin"
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser           : userDefaults,
  bodyOverlay             : false,
  isVerticalNavMenuActive : true,
  mainLayoutType          : themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList  : navbarSearchAndPinList,
  reduceButton            : true,
  verticalNavMenuWidth    : "reduce",
  verticalNavMenuItemsMin : false,
  scrollY                 : 0,
  starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
  theme                   : themeConfig.theme || "light",
  themePrimaryColor       : colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
  }

  export default state
