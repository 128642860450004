import { LOAD_CAMPAIGN, DEFAULT_VALS } from './campaign_form.js'
import { UTM_SOURCES } from './product_entities.js'
import { wlog, alog } from "./logger/logger.js"

export { CAMPAIGN_TEMPLATE }

const CAMPAIGN_TEMPLATE = {
  async make(http, host, camp_id) {
    let campaign = await LOAD_CAMPAIGN.get(http, host, camp_id)
    alog("get campaign", "original", campaign)
    const { platform } = campaign

    delete campaign.id
    delete campaign.external_id
    delete campaign.hash_code
    delete campaign.uniq_num
    campaign.daily_budget = DEFAULT_VALS.get(platform, 'campaign_budget');
    campaign.utm_source = UTM_SOURCES[platform]
    this.cleanAdsets(campaign)

    return campaign
  },
  cleanAdsets(campaign) {
    const { platform } = campaign
    campaign.initialAdsetCount = 0

    campaign.adsets = campaign.adsets.map(adset => {
      delete adset.id
      delete adset.external_id
      delete adset.campaign_id

      adset.daily_budget = DEFAULT_VALS.get(platform, 'adset_budget');
      adset.creatives = this.cleanCreatives(adset.creatives)

      return {
        serialNum: ++campaign.initialAdsetCount,
        loadedData: adset
      }
    })
  },
  cleanCreatives(creatives) {
    let creativesCounter = 0

    creatives = creatives.filter(cre => cre.status == "running")
    if (creatives.length == 0) {
      let msg = "Adset has no running creatives!"
      alert(msg)
      wlog(msg)
    }

    creatives.forEach(cre => {
      delete cre.name;
      delete cre.media_name;
      delete cre.adset_id;
      delete cre.external_id;
      cre.serialNum = ++creativesCounter
    })

    return creatives
  },
}