const { comparisonObject } = require('comparison-object-diff');
const cloneDeep = require('lodash.clonedeep');

export { getChanges, cloneDeep }

const getChanges = (before, after, successfullPayloads) => {
  const { id } = before.campaigns[0]
  const [ _before, _after ] = [before, after].map(o => cloneAndObjecitfy(o))
  let diff = comparisonObject(_before, _after)
  diff = formatPayload(diff, id, _after)
  diff = filterSuccesfull(diff, successfullPayloads)
  return diff
}

const cloneAndObjecitfy = (obj) => {
  let clonee = cloneDeep(obj)
  let campaignClonee = clonee.campaigns[0]
  let adsetsClonee = campaignClonee.adsets

  adsetsClonee = adsetsClonee.map(adset => {
    adset.ads = adset.ads.reduce(arrayToObject, {})

    return adset
  }).reduce(arrayToObject, {})
  clonee = { ...clonee, ...campaignClonee } 
  clonee.adsets = adsetsClonee
  delete clonee.campaigns
  
  return clonee
}

const arrayToObject = (acc, curr) => { acc[curr.id || `new_${curr.serialNum}`] = cloneDeep(curr); return acc};

const formatPayload = (diff, id, after) => {
  let payloads = formatChanged(diff.change, id, after)
  if (diff.del) payloads = formatDeleted(payloads, diff.del, after)
  if (diff.add) payloads = formatAdded(payloads, diff.add, after)
  return payloads.filter(p => Object.keys(p).length != 2);
}

const formatAdded = (payloads, add, after) => {
  if (!add) return;

  let obj_adsets = cloneDeep(add.adsets)
  for (let adset_id in obj_adsets) {
    let adset = obj_adsets[adset_id]

    if (adset.serialNum) {
      delete adset.id
      payloads.push({ adset_changes: true, ...adset })
      continue;
    }

    if (adset.ads) {
      for (let ad_id in adset.ads) {
        let ad = adset.ads[ad_id]
        delete ad.id
        payloads.push({ ad_changes: true, adset_id: adset_id, ...ad })
      }
      delete adset.ads
    }

    if (Object.keys(adset).length == 0) continue;

    let adsetPayload = payloads.find(p => p.adset_id && p.adset_id === adset_id);
    if(!adsetPayload){
      adsetPayload = { adset_changes: true, adset_id: adset_id};
      payloads.push(adsetPayload);
    }
    for (let key in adset) {
      adsetPayload[key] = [ ...cloneDeep(after.adsets[adset_id][key])]
    }
  }

  return payloads
}

const formatDeleted = (payloads, del, after) => {
  let obj_adsets = cloneDeep(del.adsets)
  for (let adset_id in obj_adsets) {
    let adset = obj_adsets[adset_id]

    if (adset === true) {
      payloads.push({ adset_changes: true, adset_id: adset_id, is_deleted: true })
      continue;
    }

    if (adset.ads) {
      for (let ad_id in adset.ads) {
        payloads.push({ ad_changes: true, ad_id: ad_id, is_deleted: true, adset_id: adset_id})
      }
      delete adset.ads
    }
    if (Object.keys(adset).length == 0) continue;

    let adsetDeletedPayload = {}
    for (let key in adset) {
      adsetDeletedPayload[key] = [ ...cloneDeep(after.adsets[adset_id][key])]
    }

    let existingPayloadI = payloads.findIndex(p => p.adset_id && p.adset_id == adset_id)
    if (existingPayloadI != -1) {
      adsetDeletedPayload = { ...payloads[existingPayloadI], ...adsetDeletedPayload }
      payloads.splice(existingPayloadI, 1)
    }
    payloads.push({ adset_changes: true, adset_id: adset_id, ...adsetDeletedPayload })
  }

  return payloads
}

const formatChanged = (change, id, after) => {
  let payloads = []
  let campaign = { ...cloneDeep(change), id: id, campaign_changes: true }
  delete campaign.adsets

  let obj_adsets = cloneDeep(change.adsets)
  for (let adset_id in obj_adsets) {
    let adset = obj_adsets[adset_id]

    if (adset.ads) {
      for (let ad_id in adset.ads) {
        let ad = adset.ads[ad_id]
        payloads.push({ ad_changes: true, ad_id: ad_id, adset_id: adset_id, ...ad})
      }
      delete adset.ads
    }

    if (Object.keys(adset).length > 0) {
      let adsetPayload = { adset_changes: true, adset_id: adset_id }

      for (let key in adset) {
        if (key == 'max_age' || key == 'min_age') {
          adsetPayload['min_age'] = cloneDeep(after.adsets[adset_id]['min_age'])
          adsetPayload['max_age'] = cloneDeep(after.adsets[adset_id]['max_age'])
        } else {
          adsetPayload[key] = cloneDeep(after.adsets[adset_id][key])
        }
      }
      payloads.push(adsetPayload) 
    }
  }

  return [ campaign, ...payloads ]
}

const filterSuccesfull = (current, successfull) => { 
  let _successfull = cloneDeep(successfull)

  return _.reject(current, (cur) => _.find(_successfull, function(suc) { return _.isEqual(cur, suc)}))
}
