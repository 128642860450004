"use strict";
import { COUNTRIES } from "./countries.js";
import platformOptions from "./platform_options";
import { PLATFORMS, PUBLISHERS, PIXEL_CV_CODES } from "./product_entities.js";
import cloneDeep from "lodash.clonedeep";
import _ from "lodash";
import { ilog, wlog, alog } from "./logger/logger.js";

//** add new methods to namespaces: */
export {
  NAME_GENERATORS,
  UTILS,
  ERROR,
  COMPUTED_PROPS,
  LOAD_CAMPAIGN,
  DISABLED_FIELDS,
  SERIALIZE,
  CUSTOM_CONVERSION,
  VALIDATION,
  DEFAULT_VALS,
  OPTIONS,
  FB_ATTR_WINDOW_DEFAULT,
};

const FB_ATTR_WINDOW_DEFAULT = 1;

const UTILS = {
  clone(obj) {
    return cloneDeep(obj);
  },
  isEqual(obj1, obj2) {
    return _.isEqual(obj1, obj2);
  },
  unEscape(str) {
    return (str || "").replace(/\&.*?\;/gi, (match) => {
      return String.fromCharCode(match.match(/\d+/));
    });
  },
  bindingKey() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  },
  deviceType(devices) {
    if (!devices.length) return null;
    return CODES.devices(devices).includes("dt") ? "desktop" : "mobile";
  },
  getSignedMedia(http, host, media_key, media_bucket) {
    return http
      .get(`${host}/ads/signed_media`, {
        params: {
          media_key: media_key,
          media_bucket: media_bucket,
        },
      })
      .then((res) => res.data.signed_media);
  },
  setDefaults(_this, type, force = false) {
    if (!force) {
      if (
        !window.location.href.match(/\/#\/new-campaign/) ||
        !_this.platform_options.defaults
      )
        return;
    }

    let defs = _this.platform_options.defaults[type] || {};
    for (let field in defs) {
      _this[field] =
        defs[field] === "first"
          ? _this.platform_options[field][0]
          : defs[field];
    }
  },
};

const ERROR = {
  handle(exeption, errorElement) {
    let fullError = exeption.response.data.error;
    console.log(exeption);
    console.log(fullError);
    errorElement.message = fullError.split("\n")[0];
  },
};

const COMPUTED_PROPS = {
  gurl(url, utm_medium, utm_source, campaignName, adName, platform) {
    return [
      `${url}?`,
      `&utm_campaign=${encodeURIComponent(
        campaignName.toLowerCase().replace(/ +/g, "_")
      )}`,
      `&utm_medium=${utm_medium}`,
      `&utm_source=${utm_source}`,
      `&utm_content=${encodeURIComponent(adName)}`,
      platform === "taboola" ? "&utm_term={site}" : "",
    ].join("");
  },
  maxAge(platformOptionsMaxAge, minAge) {
    if (!platformOptionsMaxAge) return {};
    return platformOptionsMaxAge.filter((age) => {
      return age >= minAge || !age;
    });
  },
};

const LOAD_CAMPAIGN = {
  get(http, host, campaignId) {
    return http
      .get(`${host}/campaigns/${campaignId}`)
      .then((res) => SERIALIZE.incoming(res.data.campaign));
  },
};

const DISABLED_FIELDS = {
  snapchat: ["text"],
  assert(field, platform = null) {
    platform = this[platform];
    if (platform == null) return false;

    let isDisabled = platform.includes(field);
    return isDisabled;
  },
};

const SERIALIZE = {
  incoming(camp) {
    const { renameProp } = this;

    renameProp.call(camp, "publisher", "publisher_id");
    renameProp.call(camp, "adAccount", "ad_account_id");
    renameProp.call(camp, "units", "article_units");
    renameProp.call(camp, "initialAdsetCount", "adsets_created_counter");
    if (camp.initialAdsetCount < 2) camp.initialAdsetCount = 1;

    const firstAd = camp.adsets[0].ads[0];
    const urlAndQuery = firstAd.url.split("?");
    camp.url = urlAndQuery[0];
    if (camp.metadata && camp.metadata.ad_format)
      camp.selectedSnapAdFormat = camp.metadata.ad_format;

    if ((firstAd.custom || {}).board_id) {
      camp.selectedBoardId = firstAd.custom.board_id;
    }

    camp.adsets.forEach((adset) => {
      renameProp.call(adset, "creatives", "ads");
      renameProp.call(adset, "device_platforms", "devices");
      renameProp.call(adset, "goal", "optimization_goal");
      renameProp.call(adset, "initialAdCount", "ads_created_counter");

      if (camp.selectedSnapAdFormat === "story" && adset.metadata) {
        adset.discoveryTile = {
          headline: adset.metadata.preview_creative_headline,
          media_key: adset.metadata.preview_creative_media_key,
          media_bucket: adset.metadata.preview_creative_media_bucket,
          signed_media: adset.metadata.preview_creative_signed_media,
        };
      }
      if (adset.custom) {
        if (adset.custom.custom_conversion_rule) {
          adset["custom_conversion"] = {
            id: adset.custom.id,
            rule: adset.custom.custom_conversion_rule,
          };
        }
        if (adset.custom.auto_placement) {
          adset["auto_placement"] = adset.custom.auto_placement == "true";
        }
        delete adset.custom;
      }

      if (adset.locales.length > 0)
        adset.locales = adset.locales.map((l) => l.code);
      if (adset.browsers.length > 0)
        adset.browsers = adset.browsers.map((l) => l.int_code);
      if (adset.countries.length > 0)
        adset.countries = adset.countries.map((c) => c.int_code);
      if (adset.device_platforms.length > 0)
        adset.device_platforms = adset.device_platforms.map((d) => d.int_code);
      if (adset.operating_systems.length > 0)
        adset.operating_systems = adset.operating_systems.map(
          (l) => l.int_code
        );

      if (adset.gender) adset.gender = adset.gender.int_code;
      if (adset.age) {
        if (adset.age.min) adset.age_min = adset.age.min;
        if (adset.age.max) adset.age_max = adset.age.max;
        delete adset.age;
      }

      adset.creatives.forEach((ad) => {
        renameProp.call(ad, "gurl", "url");
      });
    });

    return camp;
  },
  dispatching(formObj) {
    const { renameProp } = this;

    renameProp.call(formObj.campaigns[0], "article_units", "units");

    formObj.campaigns[0].adsets.forEach((adset) => {
      renameProp.call(adset, "ads", "creatives");
      renameProp.call(adset, "min_age", "age_min");
      renameProp.call(adset, "max_age", "age_max");

      adset.ads.forEach((ad) => {
        renameProp.call(ad, "url", "gurl");
      });
    });

    return formObj;
  },
  renameProp(newName, OldName) {
    this[newName] = this[OldName];
    delete this[OldName];
  },
  targetToPlatformOption(platform, arr) {
    if (!platform || (arr || []).length === 0) return [];
    let options = OPTIONS.platform(platform);
    options = [
      ...options.locales,
      ...options.countries,
      ...options.platforms,
      ...options.browsers,
      ...options.operating_systems,
    ];
    return arr.map((c) => options.find((d) => d.code == c));
  },
};

const CUSTOM_CONVERSION = {
  isSupported(objective, platform, ad_account_id) {
    return (
      objective === "OUTCOME_LEADS" &&
      platform === "facebook" &&
      !!ad_account_id
    );
  },
  isPurchase(adset) {
    return !!((adset.custom_conversion || {}).rule || "").match(/purchase/i);
  },
};

const NAME_GENERATORS = {
  uniqNum: function () {
    return `${Math.floor(Math.random() * 899999 + 100000)}`;
  },
  campaign: function (_this) {
    let val = _this.customParams.isPurchase ? "val" : null;
    let user = CODES.user(_this.creator);
    let title = _this.title;
    let uniqNum = _this.uniq_num;
    let platform = CODES.platform(_this.platform);
    let adAccount = CODES.adAccount(_this.adAccounts, _this.adAccount);
    let publisher = CODES.publisher(_this.publisher);
    let audiences = (_this.adsetAudiences || []).length > 0 ? "AD" : "";
    let utm_medium = _this.utm_medium;
    let custom_name = CODES.customName(_this.custom_name);
    return [
      publisher,
      platform,
      adAccount,
      audiences,
      val,
      title,
      utm_medium,
      user,
      custom_name,
      uniqNum,
    ]
      .filter((x) => x)
      .join(" ");
  },
  adset: function (_this) {
    let nameArr = [_this.serialNum];

    if (_this.platform === "facebook") {
      nameArr = [
        ...nameArr,
        CODES.customConversion(_this.custom_conversion),
        CODES.countries(_this.countries),
        ...CODES.ages(_this.age_min, _this.age_max),
        CODES.autoPlacement(_this.auto_placement),
        _this.attr_window,
      ];
    } else {
      nameArr = [
        ...nameArr,
        CODES.countries(_this.countries),
        ...CODES.ages(_this.age_min, _this.age_max),
        ...CODES.devices(_this.device_platforms),
        CODES.goal(_this.goal),
      ];
    }

    nameArr.push(CODES.customName(_this.custom_name));

    return nameArr.filter((x) => x).join(" ");
  },
  ad: function (utm_campaign, adset_num, ad_number) {
    let name = utm_campaign
      ? utm_campaign.toLowerCase().replace(/ /g, "_")
      : "creative";

    return `${name}_${adset_num}_${ad_number}`;
  },
};

const OPTIONS = {
  platform: (platform) => {
    let opts = platformOptions[platform] || {};

    opts.countries = COUNTRIES.countriesByPlatform(platform) || [];
    opts.groupedCountryOptions = COUNTRIES.groupedOptions(platform);

    return opts;
  },
  provided: (http, host) =>
    http.get(`${host}/campaigns/properties`).then((res) => res.data),
};

const CODES = {
  publisher: function (pub_id) {
    let pub = PUBLISHERS.find((p) => p.id == pub_id);
    if (pub) return pub.code;
  },
  platform: function (platform) {
    let pla = PLATFORMS.find((p) => p.name == platform);
    if (pla) return pla.code;
  },
  adAccount(adAccounts, adAccountId) {
    let adAccount = adAccounts.find((acc) => {
      return acc.id === adAccountId;
    });
    adAccount =
      adAccount && adAccount.code_name
        ? adAccount.code_name.toUpperCase()
        : null;
    return adAccount;
  },
  countries(countries) {
    if (!countries || countries.length == 0 || typeof countries[0] == "string")
      return [];

    let code = "WW";
    if (countries.length == 1) {
      let name = countries[0].name;
      if (name.charAt(0) === name.charAt(0).toLowerCase())
        name = name
          .split("_")
          .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
          .join(" ");
      code = COUNTRIES.displayCodes.find((item) => item.name == name).code;
    }

    let group = COUNTRIES.shortcutGroups.find((g) =>
      _.isEqual(g.countries.sort(), countries.map((c) => c.code).sort())
    );
    if (group) {
      code = group.name;
    }

    return code;
  },
  devices(devices) {
    if (!devices) return [];

    devices = devices.map((d) => {
      return d.code == "pc" ? "dt" : d.code.toLowerCase();
    });
    const android = ["android", "android_tablet"];
    const ios = ["iphone", "ipad"];
    const mobiles = [
      ...android,
      ...ios,
      "ios",
      "web_mobile",
      "other_mobile",
      "mobile",
    ];

    if (_.difference(android, devices).length == 0) {
      _.pull(devices, ...android);
      devices.push("android");
    }

    if (_.difference(ios, devices).length == 0) {
      _.pull(devices, ...ios);
      devices.push("ios");
    }

    if (
      devices.reduce((acc, curr) => acc + mobiles.includes(curr), 0) >= 2 ||
      devices.includes("mobile")
    ) {
      _.pull(devices, ...mobiles);
      devices.push("mobile");
    }
    return devices;
  },
  gender(gender) {
    if (gender == "all_genders" || !gender) return null;
    return gender.toLowerCase();
  },
  ages(min, max) {
    if (min === "Any") min = null;
    if (max === "Any") max = null;
    return max === null && min === 18 ? ["18plus"] : [min, max];
  },
  customName(custom_name) {
    if (!custom_name) return;

    return custom_name.toLowerCase();
  },
  user(user) {
    user = user.first_name.charAt(0) + user.last_name.charAt(0);
    user = user.toUpperCase();
    return user;
  },
  customConversion(custom_conversion) {
    let rule = (custom_conversion || {}).rule;
    if (!rule) return;

    let value = parseFloat(rule.match(/\d+\.?\d*/));
    if (rule.match(/{"eq":"up"}/)) value = value * 100;

    return `CV${Math.round(value)}`;
  },
  autoPlacement(ap) {
    return ap ? "AP" : null;
  },
  goal(goal) {
    return goal ? PIXEL_CV_CODES[goal] : null;
  },
};

const DEFAULT_VALS = {
  facebook: {
    bid: 0,
    age_min: 21,
    adset_budget: null,
    locales: [],
  },
  snapchat: {
    bid: 0.2,
    campaign_budget: 20,
  },
  twitter: {
    bid: 0.15,
    adset_budget: null,
  },
  pinterest: {
    campaign_budget: null,
  },
  taboola: {
    locales: [],
    bid: 0.05,
    campaign_budget: 10,
  },
  other: {
    bid: 0.01,
    adset_budget: 7,
    age_min: null,
    campaign_budget: 7,
    locales: [{ name: "English", code: "en" }],
  },
  get: function (platform, field) {
    return this[platform] && this[platform][field] !== undefined
      ? this[platform][field]
      : this["other"][field];
  },
};

const VALIDATION = {
  platform(campaign) {
    let result = true;
    if (campaign.platform === "facebook") {
      if (campaign.objective === "OUTCOME_LEADS") {
        campaign.adsets.forEach((adset) => {
          if (
            !adset.custom_conversion ||
            Object.keys(adset.custom_conversion).length === 0
          ) {
            result = validate_file.validationAlert(
              "Must specify Custom Conversion value in the adset"
            );
          } else if (CUSTOM_CONVERSION.isPurchase(adset)) {
            let roas = adset.max_bid ? parseFloat(adset.max_bid) : null;
            if ((roas && roas < 0.01) || roas > 1000) {
              result = validate_file.validationAlert(
                "Min ROAS value must be between: 0.01 and 1000"
              );
            }
          }
        });
      }
    }
    if (campaign.platform === "pinterest") {
      if (!campaign.external_board_id)
        result = validate_file.validationAlert("Must select board");

      campaign.adsets.forEach((adset) => {
        if (!adset.max_bid || adset.max_bid === "" || adset.max_bid < 0.25)
          result = validate_file.validationAlert(
            "Max bid must not be less than 0.25"
          );

        if (!adset.daily_budget || adset.daily_budget === "")
          result = validate_file.validationAlert(
            "Daily budget mut be greater than 0"
          );
      });
    }
    return result;
  },
  abTesting(abTests) {
    const overallPercentage = abTests.presets.reduce(
      (acc, ab) => acc + ab.percentage * 100,
      0
    );
    if (overallPercentage !== 100) {
      alert(
        `Monetization Presets must sum up to 100%, currently ${overallPercentage}%`
      );
      return false;
    }
    return true;
  },
  snapStory(adsets) {
    for (let adset of adsets.filter((adset) => adset.metadata)) {
      const { discovery_tile } = adset;
      if (discovery_tile && discovery_tile.media_key == null) {
        alert("Discovery Tile background image is empty");
        return false;
      }

      if (adset.creatives.length < 3) {
        alert("Number of creatives in story ads must be at least 3");
        return false;
      }
    }
    return true;
  },
  creatives(adsets) {
    let msg = "";
    for (let adset of adsets) {
      if (adset.creatives.length == 0) {
        msg = "One or more adsets have no creatives";
        wlog(msg);
        alert(msg);
        return false;
      }
      for (let c of adset.creatives) {
        if (
          (c.text && c.text.match(/\*/)) ||
          (c.headline && c.headline.match(/\*/))
        ) {
          msg =
            "illegal charecter detected in a creative's caption or headline";
          alert(msg);
          return false;
        }
      }
    }

    return true;
  },
  countries(adsets) {
    let msg = "";
    let bool = true;
    adsets.forEach((adset) => {
      if (adset.countries.length === 0) {
        msg = "Adset Must include at least one country";
        alert(msg);
        wlog(`${msg} (${adset.countries})`);
        bool = false;
      }
    });

    return bool;
  },
  locales(adsets, platform) {
    let msg = "";
    let bool = true;
    if (platform == "snapchat") {
      adsets.forEach((adset) => {
        if (adset.countries.length > 1 && adset.locales.length != 1) {
          msg =
            "Adset Must include exactly one language if it has multiple countries";
          alert(msg);
          wlog(`${msg} (${adset.countries})`);
          bool = false;
        }
      });
    }

    return bool;
  },
  utm_campaign(utmCamp) {
    let error;
    if (utmCamp.length >= 300)
      error = `Utm campaign name is too long, remove ${
        utmCamp.length - 300
      } characters and try again.`;
    if (error) {
      alert(error);
      wlog(error);
    }

    return !error;
  },
  bid(values) {
    if (!Array.isArray(values)) values = [{ max_bid: values }];
    let bids = values
      .filter(
        (adset) => adset.max_bid > 0.16 && !CUSTOM_CONVERSION.isPurchase(adset)
      )
      .map((adset) => adset.max_bid);
    if (bids.length > 0) {
      let confirmed = confirm(
        `Are you sure you want to set bid${
          bids.length > 1 ? "s" : ""
        } to ${bids.join(", ")}?`
      );
      ilog(
        `user ${confirmed ? "confirmed" : "cancled"} bid update (${bids.join(
          ", "
        )}).`
      );
      return confirmed;
    } else {
      ilog("bid valid: all below threshold");
      return true;
    }
  },
  budget(budget, platform, isAdset = false) {
    let msg = `valid budget. (${budget}, ${platform})`;
    if (
      platform == "snapchat" &&
      ((isAdset && budget < 5) || (!isAdset && budget < 20))
    ) {
      msg = "Daily Budget must be above  20$";
      alert(msg);
      wlog(msg);
      return false;
    }
    wlog(msg);
    return true;
  },
  device_platforms(devices) {
    let values = devices || [];
    if (values.length < 2) return values;

    if (
      ["pc", "mobile"].includes(values[0].code) ||
      ["pc", "mobile"].includes(values[values.length - 1].code)
    ) {
      let msg = "Unable to add PC and Mobiles platform together";
      alert(msg);
      wlog(msg);
      values.pop();
    }
    return values;
  },
  bg_tile_file(platform_options) {
    return function (file, valid) {
      const { restrictions } = platform_options;
      return validate_file.image(file, valid, restrictions.preview_image);
    };
  },
  file(platform_options, subtype) {
    return function (file, valid) {
      let msg = "validating";
      alog(msg, "file", file);

      const { restrictions } = platform_options;
      if (!restrictions) {
        msg = "must select platform before uploading file";
        wlog(msg);
        return valid(msg);
      }

      if (subtype === "preview_image")
        return validate_file.image(file, valid, restrictions.preview_image);

      if (file.type.match(/image/))
        return validate_file.image(file, valid, restrictions.image);

      if (file.type.match(/video/))
        return validate_file.video(file, valid, restrictions.video);

      msg = "unsupported file type";
      wlog(msg);
      valid(msg);
    };
  },
};

//not exported
const validate_file = {
  validationAlert(message) {
    wlog(message);
    alert(message);
    return false;
  },
  image(file, valid, restrictions) {
    if (!restrictions) return valid();

    let reader = new FileReader();
    reader.onload = (entry) => {
      let imageObj = new Image();
      imageObj.src = entry.target.result;
      imageObj.onload = function () {
        validate(this, valid, restrictions);
      };
    };

    reader.readAsDataURL(file);
  },
  video(file, valid, restrictions) {
    if (!restrictions) return valid();

    let url = URL.createObjectURL(file);
    let video = document.createElement("video");
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      validate(video, valid, restrictions);
    };
    video.src = url;
    video.load();
  },
  validate(file, valid, restrictions) {
    let result = { success: false, error: [] };
    let size_error = false;
    let fileNaturalRatio = this.calcNatural(file.width, file.height);
    let fileRationalRatio = this.calcRational(file.width, file.height);
    let rationError = restrictions
      .map(
        (r) =>
          `${this.calcRational(r.min_width, r.min_height)} (${this.calcNatural(
            r.min_width,
            r.min_height
          )})`
      )
      .join(" or ");

    restrictions.forEach((rest) => {
      if (result.success) return;

      let restNaturalRatio = this.calcNatural(rest.min_width, rest.min_height);
      if (restNaturalRatio !== fileNaturalRatio) {
        if (!size_error)
          result.error.push(
            `ratio do not match. got ${fileRationalRatio} (${fileNaturalRatio}). should be ${rationError}`
          );
      } else if (file.width < rest.min_width || file.height < rest.min_height) {
        size_error = true;
        result.error.push(
          `bad resolution. got ${file.width}x${file.height}. should be at least ${rest.min_width}x${rest.min_height}`
        );
      } else {
        result.success = true;
      }
    });

    if (result.success) {
      ilog("valid file");
      return valid();
    }

    let msg = result.error.pop();
    ilog(msg);
    valid(msg);
  },
  gcd(a, b) {
    if (b === 0) return a;

    return this.gcd(b, a % b);
  },
  calcRational(x, y) {
    let c = this.gcd(x, y);
    return "" + x / c + ":" + y / c;
  },
  calcNatural(x, y) {
    return (x / y).toFixed(2);
  },
};

const validate = validate_file.validate.bind(validate_file);
