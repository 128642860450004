import countryCodesJson from "../assets/countries.json";

export { COUNTRIES };

const COUNTRIES = {
  groupedOptions(platform) {
    let groups = this.shortcutGroups.filter((g) =>
      g.platforms.includes(platform)
    );
    let platformCountries = this.countriesByPlatform(platform) || [];

    groups = groups.map((group) => {
      let countries = null;
      if (group.exclude)
        countries = platformCountries.filter(
          (c) => !group.countries.includes(c.code)
        );
      else
        countries = platformCountries.filter((c) =>
          group.countries.includes(c.code)
        );
      return { name: group.name, countries: countries };
    });

    return [...groups, { name: "All", countries: platformCountries }];
  },
  countriesByPlatform(platform) {
    return this.countries.filter((country) =>
      country.platforms.includes(platform)
    );
  },
  shortcutGroups: [
    {
      name: "ww1",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "united_states",
        "canada",
        "australia",
        "united_kingdom",
        "new_zealand",
        "united_arab_emirates",
        "austria",
        "belgium",
        "germany",
        "france",
        "finland",
        "ireland",
        "italy",
        "iceland",
        "netherlands",
        "norway",
        "sweden",
        "singapore",
        "philippines",
        "romania",
        "hong_kong",
        "argentina",
        "bolivia",
        "chile",
        "colombia",
        "costa_rica",
        "dominican_republic",
        "ecuador",
        "guatemala",
        "honduras",
        "mexico",
        "nicaragua",
        "panama",
        "paraguay",
        "peru",
        "puerto_rico",
        "el_salvador",
        "uruguay",
        "venezuela",
        "spain",
        "algeria",
        "luxembourg",
        "monaco",
        "switzerland",
        "burundi",
        "comoros",
        "djibouti",
        "eritrea",
        "kenya",
        "madagascar",
        "malawi",
        "mayotte",
        "mozambique",
        "reunion",
        "rwanda",
        "seychelles",
        "somalia",
        "tanzania",
        "zambia",
        "angola",
        "cameroon",
        "central_african_republic",
        "chad",
        "congo",
        "equatorial_guinea",
        "gabon",
        "sao_tome_and_principe",
        "south_sudan",
        "libya",
        "morocco",
        "western_sahara",
        "benin",
        "burkina_faso",
        "cabo_verde",
        "cote_divoire",
        "gambia",
        "ghana",
        "guinea",
        "guinea_bissau",
        "liberia",
        "mali",
        "niger",
        "nigeria",
        "senegal",
        "sierra_leone",
        "togo",
        "botswana",
        "Lesotho",
        "namibia",
        "swaziland",
        "saint_helena",
        "kazakhstan",
        "kyrgyzstan",
        "tajikistan",
        "turkmenistan",
        "uzbekistan",
        "japan",
        "south_korea",
        "macau",
        "bhutan",
        "maldives",
        "nepal",
        "cambodia",
        "brunei",
        "thailand",
        "timor_leste",
        "vietnam",
        "armenia",
        "azerbaijan",
        "georgia",
        "bahrain",
        "cyprus",
        "israel",
        "kuwait",
        "oman",
        "qatar",
        "saudi_arabia",
        "yemen",
        "anguilla",
        "bahamas",
        "barbados",
        "aruba",
        "bonaire",
        "sint_eustatius_and_saba",
        "british_virgin_islands",
        "cayman_islands",
        "curacao",
        "dominica",
        "grenada",
        "guadeloupe",
        "martinique",
        "montserrat",
        "saint_barthelemy",
        "saint_kitts_and_nevis",
        "saint_martin",
        "saint_vincent_and_the_grenadines",
        "turks_and_caicos_islands",
        "belize",
        "belarus",
        "bulgaria",
        "czech_republic",
        "hungary",
        "poland",
        "moldova",
        "slovakia",
        "ukraine",
        "aland_islands",
        "denmark",
        "estonia",
        "faroe_islands",
        "greenland",
        "latvia",
        "finland",
        "guernsey",
        "isle_of_man",
        "jersey",
        "latvia",
        "lituania",
        "norway",
        "svalbard_and_jan_mayen",
        "sweden",
        "croatia",
        "gibraltar",
        "greece",
        "malta",
        "portugal",
        "slovenia",
        "serbia",
        "san_marino",
        "macedonia",
        "montenegro",
        "liechtenstein",
        "bermuda",
        "saint_pierre_and_miquelon",
        "french_guiana",
        "french_polynesia",
        "new_caledonia",
        "wallis_and_futuna",
        "fiji",
        "kiribati",
        "marshall_islands",
        "micronesia",
        "nauru",
        "palau",
        "papua_new_guinea",
        "samoa",
        "solomon_islands",
        "tonga",
        "tuvalu",
        "vanuatu",
        "american_samoa",
        "cook_islands",
        "brazil",
        "suriname",
        "niue",
        "tokelau",
      ],
    },
    {
      name: "eng1",
      platforms: ["twitter", "facebook", "taboola"],
      countries: ["united_states", "canada", "australia", "united_kingdom"],
    },
    {
      name: "eng2",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "united_states",
        "canada",
        "australia",
        "south_africa",
        "hong_kong",
      ],
    },
    {
      name: "eng3",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "united_states",
        "canada",
        "australia",
        "united_kingdom",
        "new_zealand",
        "united_arab_emirates",
        "austria",
        "belgium",
        "germany",
        "france",
        "finland",
        "ireland",
        "italy",
        "iceland",
        "netherlands",
        "norway",
        "sweden",
        "singapore",
        "philippines",
        "romania",
        "hong_kong",
      ],
    },
    {
      name: "es1",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "argentina",
        "colombia",
        "venezuela",
        "uruguay",
        "mexico",
        "chile",
        "puerto_rico",
        "spain",
      ],
    },
    {
      name: "es2",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "argentina",
        "bolivia",
        "chile",
        "colombia",
        "costa_rica",
        "dominican_republic",
        "ecuador",
        "equatorial_guinea",
        "guatemala",
        "honduras",
        "mexico",
        "nicaragua",
        "panama",
        "paraguay",
        "peru",
        "el_salvador",
        "united_states",
        "uruguay",
        "venezuela",
      ],
    },
    {
      name: "es3",
      platforms: ["twitter", "facebook", "taboola"],
      countries: [
        "argentina",
        "bolivia",
        "chile",
        "colombia",
        "costa_rica",
        "dominican_republic",
        "ecuador",
        "equatorial_guinea",
        "guatemala",
        "honduras",
        "mexico",
        "nicaragua",
        "panama",
        "paraguay",
        "peru",
        "puerto_rico",
        "el_salvador",
        "united_states",
        "uruguay",
        "venezuela",
        "spain",
      ],
    },
    {
      name: "fr1",
      platforms: ["facebook", "snapchat", "twitter"],
      countries: ["france", "canada"],
    },
    {
      name: "fr2",
      platforms: ["facebook", "snapchat", "twitter"],
      countries: ["france", "belgium", "canada"],
    },
    {
      name: "fr3",
      platforms: ["facebook", "snapchat", "twitter"],
      countries: [
        "france",
        "belgium",
        "canada",
        "algeria",
        "luxembourg",
        "monaco",
        "switzerland",
      ],
    },
  ],
  displayCodes: countryCodesJson,
  countries: [
    {
      name: "United States",
      code: "united_states",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mexico",
      code: "mexico",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Brazil",
      code: "brazil",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "France",
      code: "france",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Germany",
      code: "germany",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Japan",
      code: "japan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Canada",
      code: "canada",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Italy",
      code: "italy",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Australia",
      code: "australia",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Argentina",
      code: "argentina",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Afghanistan",
      code: "afghanistan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Aland Islands",
      code: "aland_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Albania",
      code: "albania",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Algeria",
      code: "algeria",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "American Samoa",
      code: "american_samoa",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Andorra",
      code: "andorra",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Angola",
      code: "angola",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Anguilla",
      code: "anguilla",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Antarctica",
      code: "antarctica",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Antigua",
      code: "antigua",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Antigua and Barbuda",
      code: "antigua",
      platforms: ["taboola"],
    },
    {
      name: "Armenia",
      code: "armenia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Aruba",
      code: "aruba",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Austria",
      code: "austria",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Azerbaijan",
      code: "azerbaijan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bahamas",
      code: "the_bahamas",
      platforms: ["taboola"],
    },
    {
      name: "Bahrain",
      code: "bahrain",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bangladesh",
      code: "bangladesh",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Barbados",
      code: "barbados",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Belarus",
      code: "belarus",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Belgium",
      code: "belgium",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Belize",
      code: "belize",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Benin",
      code: "benin",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bermuda",
      code: "bermuda",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bhutan",
      code: "bhutan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bolivia",
      code: "bolivia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bonaire, Sint Eustatius and Saba",
      code: "bonaire_sint_eustatius_and_saba",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Bosnia and Herzegovina",
      code: "bosnia_and_herzegovina",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Botswana",
      code: "botswana",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Bouvet Island",
      code: "bouvet_island",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "British Indian Ocean Territory",
      code: "british_indian_ocean_territory",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "British Virgin Islands",
      code: "british_virgin_islands",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Brunei",
      code: "brunei",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Brunei Darussalam",
      code: "brunei",
      platforms: ["taboola"],
    },
    {
      name: "Bulgaria",
      code: "bulgaria",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Burkina Faso",
      code: "burkina_faso",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Burundi",
      code: "burundi",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cambodia",
      code: "cambodia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cameroon",
      code: "cameroon",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cape Verde",
      code: "cape_verde",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cayman Islands",
      code: "cayman_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Central African Republic",
      code: "central_african_republic",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Chad",
      code: "chad",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Chile",
      code: "chile",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "China",
      code: "china",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Christmas Island",
      code: "christmas_island",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "cocos_keeling_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Colombia",
      code: "colombia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Comoros",
      code: "comoros",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Congo",
      code: "congo",
      platforms: ["taboola"],
    },
    {
      name: "Cook Islands",
      code: "cook_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Costa Rica",
      code: "costa_rica",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cote D'Ivoire",
      code: "cote_divoire",
      platforms: ["taboola"],
    },
    {
      name: "Croatia",
      code: "croatia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Cuba",
      code: "cuba",
      platforms: ["taboola"],
    },
    {
      name: "curacao",
      code: "curacao",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Cyprus",
      code: "cyprus",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Czech Republic",
      code: "czech_republic",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Côte d'Ivoire",
      code: "cote_divoire",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Democratic Republic of the Congo",
      code: "democratic_republic_of_the_congo",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Denmark",
      code: "denmark",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Djibouti",
      code: "djibouti",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Dominica",
      code: "dominica",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Dominican Republic",
      code: "dominican_republic",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Ecuador",
      code: "ecuador",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Egypt",
      code: "egypt",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "El Salvador",
      code: "el_salvador",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Equatorial Guinea",
      code: "equatorial_guinea",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Eritrea",
      code: "eritrea",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Estonia",
      code: "estonia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Ethiopia",
      code: "ethiopia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Falkland Islands",
      code: "falkland_islands",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Falkland Islands (Malvinas)",
      code: "falkland_islands",
      platforms: ["taboola"],
    },
    {
      name: "Faroe Islands",
      code: "faroe_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Federated States of Micronesia",
      code: "federated_states_of_micronesia",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Fiji",
      code: "fiji",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Finland",
      code: "finland",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "French Guiana",
      code: "french_guiana",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "French Polynesia",
      code: "french_polynesia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "French Southern Territories",
      code: "french_southern_territories",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Gabon",
      code: "gabon",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Gambia",
      code: "the_gambia",
      platforms: ["taboola"],
    },
    {
      name: "Georgia",
      code: "georgia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Ghana",
      code: "ghana",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Gibraltar",
      code: "gibraltar",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Greece",
      code: "greece",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Greenland",
      code: "greenland",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Grenada",
      code: "grenada",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guadeloupe",
      code: "guadeloupe",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guam",
      code: "guam",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guatemala",
      code: "guatemala",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guernsey",
      code: "guernsey",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guinea",
      code: "guinea",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guinea-Bissau",
      code: "guinea_bissau",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Guyana",
      code: "guyana",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Haiti",
      code: "haiti",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Heard Island and McDonald Islands",
      code: "heard_island_and_mcdonald_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Holy See (Vatican City State)",
      code: "vatican_city)",
      platforms: ["taboola"],
    },
    {
      name: "Honduras",
      code: "honduras",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Hong Kong",
      code: "hong_kong",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Hungary",
      code: "hungary",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Iceland",
      code: "iceland",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "India",
      code: "india",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Indonesia",
      code: "indonesia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Iran, Islamic Republic of",
      code: "iran",
      platforms: ["taboola"],
    },
    {
      name: "Iraq",
      code: "iraq",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Ireland",
      code: "ireland",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Isle Of Man",
      code: "isle_of_man",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Isle of Man",
      code: "isle_of_man",
      platforms: ["taboola"],
    },
    {
      name: "Israel",
      code: "israel",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Jamaica",
      code: "jamaica",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Jersey",
      code: "jersey",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Jordan",
      code: "jordan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Kazakhstan",
      code: "kazakhstan",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Kazakstan",
      code: "kazakhstan",
      platforms: ["taboola"],
    },
    {
      name: "Kenya",
      code: "kenya",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Kiribati",
      code: "kiribati",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Korea, Democratic People's Republic of",
      code: "south_korea",
      platforms: ["taboola"],
    },
    {
      name: "Kosovo",
      code: "kosovo",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Kuwait",
      code: "kuwait",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Kyrgyzstan",
      code: "kyrgyzstan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Lao People's Democratic Republic",
      code: "laos",
      platforms: ["taboola"],
    },
    {
      name: "Laos",
      code: "laos",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Latvia",
      code: "latvia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Lebanon",
      code: "lebanon",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Lesotho",
      code: "lesotho",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Liberia",
      code: "liberia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Libya",
      code: "libya",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Libyan Arab Jamahiriya",
      code: "libya",
      platforms: ["taboola"],
    },
    {
      name: "Liechtenstein",
      code: "liechtenstein",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Lithuania",
      code: "lithuania",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Luxembourg",
      code: "luxembourg",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Macau",
      code: "macau",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Macedonia",
      code: "macedonia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Madagascar",
      code: "madagascar",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Malawi",
      code: "malawi",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Malaysia",
      code: "malaysia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Maldives",
      code: "maldives",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mali",
      code: "mali",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Malta",
      code: "malta",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Marshall Islands",
      code: "marshall_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Martinique",
      code: "martinique",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mauritania",
      code: "mauritania",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mauritius",
      code: "mauritius",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mayotte",
      code: "mayotte",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Micronesia, Federated States of",
      code: "federated_states_of_micronesia",
      platforms: ["taboola"],
    },
    {
      name: "Moldova",
      code: "moldova",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Moldova, Republic of",
      code: "moldova",
      platforms: ["taboola"],
    },
    {
      name: "Monaco",
      code: "monaco",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mongolia",
      code: "mongolia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Montenegro",
      code: "montenegro",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Montserrat",
      code: "montserrat",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Morocco",
      code: "morocco",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Mozambique",
      code: "mozambique",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Myanmar",
      code: "myanmar",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Namibia",
      code: "namibia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Nauru",
      code: "nauru",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Nepal",
      code: "nepal",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Netherlands",
      code: "netherlands",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Netherlands Antilles",
      code: "netherlands_antilles",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "New Caledonia",
      code: "new_caledonia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "New Zealand",
      code: "new_zealand",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Nicaragua",
      code: "nicaragua",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Niger",
      code: "niger",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Nigeria",
      code: "nigeria",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Niue",
      code: "niue",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Norfolk Island",
      code: "norfolk_island",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Northern Mariana Islands",
      code: "northern_mariana_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Norway",
      code: "norway",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Oman",
      code: "oman",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Pakistan",
      code: "pakistan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Palau",
      code: "palau",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Palestine",
      code: "palestine",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Palestinian Territory",
      code: "palestine",
      platforms: ["taboola"],
    },
    {
      name: "Panama",
      code: "panama",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Papua New Guinea",
      code: "papua_new_guinea",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Paraguay",
      code: "paraguay",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Peru",
      code: "peru",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Philippines",
      code: "philippines",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Pitcairn",
      code: "pitcairn",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Pitcairn Islands",
      code: "pitcairn",
      platforms: ["taboola"],
    },
    {
      name: "Poland",
      code: "poland",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Portugal",
      code: "portugal",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Puerto Rico",
      code: "puerto_rico",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Qatar",
      code: "qatar",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Republic of the Congo",
      code: "republic_of_the_congo",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "reunion",
      code: "reunion",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Reunion",
      code: "reunion",
      platforms: ["taboola"],
    },
    {
      name: "Romania",
      code: "romania",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Russia",
      code: "russia",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Russian Federation",
      code: "russia",
      platforms: ["taboola"],
    },
    {
      name: "Rwanda",
      code: "rwanda",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saint Barthelemy",
      code: "saint_barthelemy",
      platforms: ["taboola"],
    },
    {
      name: "Saint Barthélemy",
      code: "saint_barthelemy",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Saint Helena",
      code: "saint_helena",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saint Kitts and Nevis",
      code: "saint_kitts_and_nevis",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saint Lucia",
      code: "st_lucia",
      platforms: ["taboola"],
    },
    {
      name: "Saint Martin",
      code: "saint_martin",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "saint_pierre_and_miquelon",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "saint_vincent_and_the_grenadines",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Samoa",
      code: "samoa",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "San Marino",
      code: "san_marino",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Sao Tome and Principe",
      code: "sao_tome_and_principe",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Saudi Arabia",
      code: "saudi_arabia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Senegal",
      code: "senegal",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Serbia",
      code: "serbia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Seychelles",
      code: "seychelles",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Sierra Leone",
      code: "sierra_leone",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Singapore",
      code: "singapore",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Sint Maarten",
      code: "sint_maarten",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Slovakia",
      code: "slovakia",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Slovenia",
      code: "slovenia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Solomon Islands",
      code: "solomon_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Somalia",
      code: "somalia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "South Africa",
      code: "south_africa",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      code: "south_georgia_and_the_south_sandwich_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "South Korea",
      code: "south_korea",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "South Sudan",
      code: "south_sudan",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Spain",
      code: "spain",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Sri Lanka",
      code: "sri_lanka",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "St. Lucia",
      code: "st_lucia",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Sudan",
      code: "sudan",
      platforms: ["taboola"],
    },
    {
      name: "Suriname",
      code: "suriname",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "svalbard_and_jan_mayen",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Swaziland",
      code: "swaziland",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Sweden",
      code: "sweden",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Switzerland",
      code: "switzerland",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Syrian Arab Republic",
      code: "syria",
      platforms: ["taboola"],
    },
    {
      name: "Taiwan",
      code: "taiwan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tajikistan",
      code: "tajikistan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tanzania",
      code: "tanzania",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Tanzania, United Republic of",
      code: "tanzania",
      platforms: ["taboola"],
    },
    {
      name: "Thailand",
      code: "thailand",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "The Bahamas",
      code: "the_bahamas",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "The Democratic Republic of the Congo",
      code: "democratic_republic_of_the_congo",
      platforms: ["taboola"],
    },
    {
      name: "The Gambia",
      code: "the_gambia",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Timor-Leste",
      code: "timor_leste",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Togo",
      code: "togo",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tokelau",
      code: "tokelau",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tonga",
      code: "tonga",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Trinidad and Tobago",
      code: "trinidad_and_tobago",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tunisia",
      code: "tunisia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Turkey",
      code: "turkey",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Turkmenistan",
      code: "turkmenistan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Turks and Caicos Islands",
      code: "turks_and_caicos_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Tuvalu",
      code: "tuvalu",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Uganda",
      code: "uganda",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Ukraine",
      code: "ukraine",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "United Arab Emirates",
      code: "united_arab_emirates",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "United Kingdom",
      code: "united_kingdom",
      platforms: ["facebook", "pinterest", "snapchat", "taboola", "twitter"],
    },
    {
      name: "United States Minor Outlying Islands",
      code: "united_states_minor_outlying_islands",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Uruguay",
      code: "uruguay",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "US Territories",
      code: "us_territories",
      platforms: ["pinterest"],
    },
    {
      name: "US Virgin Islands",
      code: "us_virgin_islands",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Uzbekistan",
      code: "uzbekistan",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Vanuatu",
      code: "vanuatu",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Vatican City",
      code: "vatican_city",
      platforms: ["facebook", "snapchat", "twitter"],
    },
    {
      name: "Venezuela",
      code: "venezuela",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Vietnam",
      code: "vietnam",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Virgin Islands, British",
      code: "british_virgin_islands",
      platforms: ["taboola"],
    },
    {
      name: "Virgin Islands, U.S.",
      code: "us_virgin_islands",
      platforms: ["taboola"],
    },
    {
      name: "Wallis and Futuna",
      code: "wallis_and_futuna",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Western Sahara",
      code: "western_sahara",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Yemen",
      code: "yemen",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Zambia",
      code: "zambia",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
    {
      name: "Zimbabwe",
      code: "zimbabwe",
      platforms: ["facebook", "snapchat", "taboola", "twitter"],
    },
  ],
};
